import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import { createVuetify } from '@common/lib/modules/vuetify';
import { createRouter } from '@common/lib/modules/router';
import { createI18n } from '@common/lib/modules/i18n';
import routes from './app/routes';
import themes from './app/themes';
import messages from './i18n';
import 'vue3-openlayers/dist/vue3-openlayers.css';
import './assets/scss/styles.scss';
import { setErrorHandlers } from '@common/lib/utils';
import { createAuthGuard } from '@common/index';

const app = createApp(App);
setErrorHandlers(app);

const pinia = createPinia();
const router = createRouter(routes);
const i18n = createI18n('hu', messages);
const vuetify = createVuetify(
  i18n,
  themes,
  {
    VTextField: {
      variant: 'outlined',
      density: 'compact',
      color: 'primary'
    }
  },
  'dark'
);

app.use(pinia);

createAuthGuard(router, 'login');

app.use(router);
app.use(i18n);
app.use(vuetify);
app.mount('#app');
