import type { RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'orders',
    path: '/orders',
    component: () => import('../pages/OrdersPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.ORDER_LIST
    }
  },
  {
    name: 'availableOrders',
    path: '/available-orders',
    component: () => import('../pages/AvailableOrdersPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.AVAILABLE_ORDER_LIST
    }
  }
];

export default routes;
