import type { ThemeDefinition } from 'vuetify';

const light: ThemeDefinition = {
  dark: true,
  colors: {}
};

const dark: ThemeDefinition = {
  dark: true,
  colors: {
    primary: '#026f47',
    secondary: '#00448c',
    success: '#4caf50',
    info: '#3498ff',
    warning: '#ffb300',
    error: '#df2026'
  }
};

export default { light, dark };
