import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    name: 'offerCalculator',
    path: '/customers/:customerId/offers/calculator',
    props: true,
    component: () => import('../pages/OfferCalculatorPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'offerSummary',
    path: '/calculator/summary',
    component: () => import('../pages/OfferSummaryTest.vue'),
    meta: {
      requiresAuth: true
    }
  }
];

export default routes;
